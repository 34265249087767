import * as yup from "yup"

yup.setLocale({
  mixed: {
    required: () => "Обязательное поле",
    oneOf: () => "Не соответствует новому паролю",
    string: {
      required: () => "Обязательное поле",
      min: ({ min }) => `Не менее ${min} символов`
    },
    number: {
      min: ({ min }) => `Должен быть больше или равен ${min}`,
      max: ({ max }) => `Должен быть меньше или равен ${max}`
    },
    array: {
      min: ({ min }) => `Должно быть выбрано не менее ${min} элементов`,
      max: ({ max }) => `Должно быть меньше или равно ${max} элементов`
    }
  }
})

export default yup
