import config from "./config"

import { store } from "@store/store"

import { http, storage, i18n } from "@services"

// import "assets/styles/main.scss";

i18n.init({
  languages: config.language.list,
  currentLanguage: storage.local.get(config.language.key),
  initialLanguage: config.language.initial,
  onChange: (language) => storage.local.set("language", language)
})

http.init({
  configFn: () => {
    const state = store.getState()
    const token = state.auth.tokens.accessToken
    return {
      baseURL: config.api.base_url,
      headers: {
        ...(token ? { Authorization: `Bearer ${token}` } : {})
      }
    }
  }
})
