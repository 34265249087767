// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from "./authentication"
import { combineReducers } from "redux"

const rootReducer = combineReducers({
  auth,
  navbar,
  layout
})

export default rootReducer
