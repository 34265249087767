import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { handleLogout, handleProfile } from '@store/authentication'
import { toast } from "react-toastify"
import * as Api from '../api'
import * as Mappers from '../mappers'

const Auth = ({ children }) => {

  const dispatch = useDispatch()

  const accessToken = useSelector(state => state.auth.tokens.accessToken)

  useQuery(
    ['auth', 'profile', accessToken],
    async () => {
      const { data } = await Api.Profile({ accessToken })
      return Mappers.Profile(data)
    },
    {
      enabled: !!accessToken,
      onSuccess: profile => {
        dispatch(handleProfile({ profile }))
      },
      onError: () => {
        toast.error("Internal error!")
        dispatch(handleLogout())
      },
      retry: false
    }
  )

  return children
}

export default Auth
