
import { http } from '@services'

export const Login = ({ values }) => http.request.post(
  `/v1/oauth/token`,
  {
    username: values?.username,
    password: values?.password
  }
)

export const Logout = () => http.request.post('/v1/oauth/logout')

export const Profile = ({ accessToken }) => http.request.post(`/v1/oauth/token/check_token`, {
  token: accessToken
})
