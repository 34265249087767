
const ENV = process.env

const config = {
    app: {
        env: ENV.REACT_APP_ENV,
        version: ENV.REACT_APP_VERSION,
        technology: ENV.REACT_APP_TECHNOLOGY,
        isBeta: !!ENV.REACT_APP_IS_BETA,
        isDev: ENV.REACT_APP_ENV !== 'production',
        public_url: ENV.PUBLIC_URL
    },
    language: {
        key: 'language',
        initial: 'uz',
        list: ['uz', 'oz', 'ru', 'en']
    },
    api: {
        base_url: ENV.REACT_APP_API_BASE_URL,
        access_token_key: 'access-token',
        refresh_token_key: 'refresh-token'
    },
    list: {
        perPage: 20
    }
}

export default config
