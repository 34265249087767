// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { createTransform, persistReducer } from 'redux-persist'
import persistStorage from 'redux-persist/lib/storage'

// ** UseJWT import to get config

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    isAuthenticated: false,
    isFetched: true,
    tokens: {
      accessToken: '',
      refreshToken: ''
    },
    profile: {
      id: '',
      firstName: '',
      lastName: '',
      middleName: '',
      fullName: '',
      clientId: '',
      authorities: []
    }
  },
  reducers: {
    handleLogin: (state, action) => {
      const { tokens } = action.payload

      state.isAuthenticated = true
      state.isFetched = false
      state.tokens = tokens
    },
    handleLogout: state => {
      state.isAuthenticated = false
      state.isFetched = true
      state.tokens = {
        accessToken: '',
        refreshToken: ''
      }
    },
    handleProfile: (state, action) => {
      const { profile } = action.payload
      state.isAuthenticated = true
      state.isFetched = true
      state.profile = profile
    }
  }
})

export const { handleLogin, handleLogout, handleProfile } = authSlice.actions

const isFetchedTransform = createTransform(
  state => state,
  (isFetched, key, stored) => {
    const tokens = JSON.parse(stored.tokens)

    return !tokens.accessToken
  },
  { whitelist: ['isFetched'] }
)

const persistConfig = {
  key: 'auth',
  storage: persistStorage,
  whitelist: ['isFetched', 'tokens'],
  transforms: [isFetchedTransform]
}


export default persistReducer(persistConfig, authSlice.reducer)
